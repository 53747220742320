import React from "react"
import SEO from "../components/seo"
import Home from "../components/Home3x3/Home"
// import { useTranslation } from "react-i18next"


const IndexPage = (props) => {
  // const { t } = useTranslation()

  return (
    <div>
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <Home />
    </div>
  )
}
export default IndexPage