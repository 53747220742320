import React from 'react';

const AllSchemesSvg = () => {
    return <div style={{ width: '100%'}}>
        <svg viewBox="0 0 3236 4011" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="scheme_first" transform="translate(-195.000000, 0.000000)">
                    <g id="arrow" transform="translate(2567.125370, 1950.656494) rotate(-313.000000) translate(-2567.125370, -1950.656494) translate(2344.125370, 1718.156494)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square">
                        <path d="M446,3.6379233e-12 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="arrow" transform="translate(1338.125370, 1950.656494) rotate(-313.000000) translate(-1338.125370, -1950.656494) translate(1115.125370, 1718.156494)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square">
                        <path d="M446,-5.55111512e-17 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="4" transform="translate(1223.000000, 1532.000000)" >
                        <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)">
                            <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                        </g>
                        <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" strokeWidth="4">
                            <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 L243.203857,81.5692346 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" fill="#3CFD03"></path>
                        </g>
                        <g id="Group" transform="translate(212.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                            <path d="M121.677228,127.553092 C126.444387,125.313408 134.159357,125.306773 138.940639,127.553092 L256.388237,182.731778 C261.155397,184.971462 261.169519,188.596074 256.388237,190.842393 L138.940639,246.021079 C134.17348,248.260763 126.458509,248.267398 121.677228,246.021079 L4.22962933,190.842393 C-0.537529912,188.602709 -0.551652121,184.978097 4.22962933,182.731778 L121.677228,127.553092 L121.677228,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M274.413689,191.200412 L391.861287,246.379097 C396.628447,248.618782 396.642569,252.243393 391.861287,254.489713 L274.413689,309.668398 C269.646529,311.908083 261.931559,311.914718 257.150278,309.668398 L139.702679,254.489713 C134.93552,252.250028 134.921398,248.625417 139.702679,246.379097 L257.150278,191.200412 C261.917437,188.960727 269.632407,188.954092 274.413689,191.200412 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M391.269414,254.211641 C396.036573,251.971957 403.751543,251.965322 408.532825,254.211641 L525.980424,309.390327 C530.747583,311.630012 530.761705,315.254623 525.980424,317.500943 L408.532825,372.679628 C403.765666,374.919313 396.050696,374.925948 391.269414,372.679628 L273.821815,317.500943 C269.054656,315.261258 269.040534,311.636647 273.821815,309.390327 L391.269414,254.211641 L391.269414,254.211641 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M274.243521,64.1838439 L391.69112,119.362529 C396.458279,121.602214 396.472402,125.226826 391.69112,127.473145 L274.243521,182.651831 C269.476362,184.891515 261.761392,184.89815 256.980111,182.651831 L139.532512,127.473145 C134.765353,125.23346 134.751231,121.608849 139.532512,119.362529 L256.980111,64.1838439 C261.74727,61.9441593 269.46224,61.9375244 274.243521,64.1838439 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M392.623328,127.911111 C397.390487,125.671426 405.105457,125.664791 409.886739,127.911111 L527.334337,183.089796 C532.101497,185.329481 532.115619,188.954092 527.334337,191.200412 L409.886739,246.379097 C405.119579,248.618782 397.404609,248.625417 392.623328,246.379097 L275.175729,191.200412 C270.40857,188.960727 270.394448,185.336116 275.175729,183.089796 L392.623328,127.911111 L392.623328,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                            <path d="M544.005875,190.92234 L661.453474,246.101026 C666.220633,248.340711 666.234755,251.965322 661.453474,254.211641 L544.005875,309.390327 C539.238716,311.630012 531.523746,311.636647 526.742464,309.390327 L409.294865,254.211641 C404.527706,251.971957 404.513584,248.347345 409.294865,246.101026 L526.742464,190.92234 C531.509623,188.682656 539.224593,188.676021 544.005875,190.92234 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M392.966982,1.68225245 C397.734141,-0.557432174 405.449111,-0.564067005 410.230393,1.68225245 L527.677991,56.860938 C532.445151,59.1006227 532.459273,62.7252341 527.677991,64.9715535 L410.230393,120.150239 C405.463233,122.389924 397.748263,122.396559 392.966982,120.150239 L275.519383,64.9715535 C270.752224,62.7318689 270.738102,59.1072575 275.519383,56.860938 L392.966982,1.68225245 L392.966982,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M526.428042,63.7760106 C531.195202,61.536326 538.910172,61.5296912 543.691453,63.7760106 L661.139052,118.954696 C665.906211,121.194381 665.920333,124.818992 661.139052,127.065312 L543.691453,182.243997 C538.924294,184.483682 531.209324,184.490317 526.428042,182.243997 L408.980444,127.065312 C404.213284,124.825627 404.199162,121.201016 408.980444,118.954696 L526.428042,63.7760106 L526.428042,63.7760106 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M660.547178,126.787241 C665.314338,124.547556 673.029308,124.540921 677.810589,126.787241 L795.258188,181.965926 C800.025347,184.205611 800.039469,187.830222 795.258188,190.076542 L677.810589,245.255227 C673.04343,247.494912 665.32846,247.501547 660.547178,245.255227 L543.09958,190.076542 C538.33242,187.836857 538.318298,184.212246 543.09958,181.965926 L660.547178,126.787241 L660.547178,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                        </g>
                    </g>
                    <g id="3" transform="translate(2406.000000, 1534.000000)" >
                        <g id="Group" transform="translate(214.257453, 0.000000)" stroke="#090909" strokeWidth="4" fill="#FFFFFF">
                            <path d="M121.677228,127.553092 C126.444387,125.313408 134.159357,125.306773 138.940639,127.553092 L256.388237,182.731778 C261.155397,184.971462 261.169519,188.596074 256.388237,190.842393 L138.940639,246.021079 C134.17348,248.260763 126.458509,248.267398 121.677228,246.021079 L4.22962933,190.842393 C-0.537529912,188.602709 -0.551652121,184.978097 4.22962933,182.731778 L121.677228,127.553092 L121.677228,127.553092 Z" id="Rectangle-1"></path>
                            <path d="M274.413689,191.200412 L391.861287,246.379097 C396.628447,248.618782 396.642569,252.243393 391.861287,254.489713 L274.413689,309.668398 C269.646529,311.908083 261.931559,311.914718 257.150278,309.668398 L139.702679,254.489713 C134.93552,252.250028 134.921398,248.625417 139.702679,246.379097 L257.150278,191.200412 C261.917437,188.960727 269.632407,188.954092 274.413689,191.200412 Z" id="Rectangle-1"></path>
                            <path d="M391.269414,254.211641 C396.036573,251.971957 403.751543,251.965322 408.532825,254.211641 L525.980424,309.390327 C530.747583,311.630012 530.761705,315.254623 525.980424,317.500943 L408.532825,372.679628 C403.765666,374.919313 396.050696,374.925948 391.269414,372.679628 L273.821815,317.500943 C269.054656,315.261258 269.040534,311.636647 273.821815,309.390327 L391.269414,254.211641 L391.269414,254.211641 Z" id="Rectangle-1"></path>
                            <path d="M256.980111,64.1838439 C261.74727,61.9441593 269.46224,61.9375244 274.243521,64.1838439 L391.69112,119.362529 C396.458279,121.602214 396.472402,125.226826 391.69112,127.473145 L274.243521,182.651831 C269.476362,184.891515 261.761392,184.89815 256.980111,182.651831 L139.532512,127.473145 C134.765353,125.23346 134.751231,121.608849 139.532512,119.362529 L256.980111,64.1838439 L256.980111,64.1838439 Z" id="Rectangle-1"></path>
                            <path d="M409.886739,127.911111 L527.334337,183.089796 C532.101497,185.329481 532.115619,188.954092 527.334337,191.200412 L409.886739,246.379097 C405.119579,248.618782 397.404609,248.625417 392.623328,246.379097 L275.175729,191.200412 C270.40857,188.960727 270.394448,185.336116 275.175729,183.089796 L392.623328,127.911111 C397.390487,125.671426 405.105457,125.664791 409.886739,127.911111 Z" id="Rectangle-1"></path>
                            <path d="M544.005875,190.92234 L661.453474,246.101026 C666.220633,248.340711 666.234755,251.965322 661.453474,254.211641 L544.005875,309.390327 C539.238716,311.630012 531.523746,311.636647 526.742464,309.390327 L409.294865,254.211641 C404.527706,251.971957 404.513584,248.347345 409.294865,246.101026 L526.742464,190.92234 C531.509623,188.682656 539.224593,188.676021 544.005875,190.92234 Z" id="Rectangle-1"></path>
                            <path d="M392.966982,1.68225245 C397.734141,-0.557432174 405.449111,-0.564067005 410.230393,1.68225245 L527.677991,56.860938 C532.445151,59.1006227 532.459273,62.7252341 527.677991,64.9715535 L410.230393,120.150239 C405.463233,122.389924 397.748263,122.396559 392.966982,120.150239 L275.519383,64.9715535 C270.752224,62.7318689 270.738102,59.1072575 275.519383,56.860938 L392.966982,1.68225245 L392.966982,1.68225245 Z" id="Rectangle-1"></path>
                            <path d="M526.428042,63.7760106 C531.195202,61.536326 538.910172,61.5296912 543.691453,63.7760106 L661.139052,118.954696 C665.906211,121.194381 665.920333,124.818992 661.139052,127.065312 L543.691453,182.243997 C538.924294,184.483682 531.209324,184.490317 526.428042,182.243997 L408.980444,127.065312 C404.213284,124.825627 404.199162,121.201016 408.980444,118.954696 L526.428042,63.7760106 L526.428042,63.7760106 Z" id="Rectangle-1"></path>
                            <path d="M660.547178,126.787241 C665.314338,124.547556 673.029308,124.540921 677.810589,126.787241 L795.258188,181.965926 C800.025347,184.205611 800.039469,187.830222 795.258188,190.076542 L677.810589,245.255227 C673.04343,247.494912 665.32846,247.501547 660.547178,245.255227 L543.09958,190.076542 C538.33242,187.836857 538.318298,184.212246 543.09958,181.965926 L660.547178,126.787241 L660.547178,126.787241 Z" id="Rectangle-1"></path>
                        </g>
                        <g id="Group" transform="translate(406.092850, 506.986011) rotate(-302.000000) translate(-406.092850, -506.986011) translate(35.092850, 260.986011)" stroke="#090909" strokeWidth="4">
                            <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" fill="#807C7C"></path>
                            <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" fill="#807C7C"></path>
                        </g>
                        <g id="Group" transform="translate(816.339647, 504.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -504.329906) translate(437.839647, 266.329906)">
                            <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 L243.203857,81.5692346 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                        </g>
                    </g>
                    <g id="arrow" transform="translate(2566.625513, 1432.522425) scale(-1, 1) rotate(-351.000000) translate(-2566.625513, -1432.522425) translate(2343.625513, 1200.022425)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square" >
                        <path d="M446,-5.55111512e-17 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="2" transform="translate(1835.000000, 768.000000)" >
                        <g id="flower+color_down" transform="translate(0.889297, 0.454958)">
                            <g id="Group" transform="translate(194.652108, 0.000000)" stroke="#090909" strokeWidth="4">
                                <path d="M114.984488,119.546232 C119.489434,117.447138 126.780049,117.44092 131.298341,119.546232 L242.285846,171.261198 C246.790792,173.360292 246.804137,176.757376 242.285846,178.862688 L131.298341,230.577655 C126.793395,232.676748 119.502779,232.682967 114.984488,230.577655 L3.99698258,178.862688 C-0.507963589,176.763595 -0.52130902,173.36651 3.99698258,171.261198 L114.984488,119.546232 L114.984488,119.546232 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M259.319824,179.198233 L370.307329,230.913199 C374.812275,233.012293 374.825621,236.409377 370.307329,238.514689 L259.319824,290.229656 C254.814878,292.328749 247.524263,292.334968 243.005971,290.229656 L132.018466,238.514689 C127.51352,236.415596 127.500174,233.018511 132.018466,230.913199 L243.005971,179.198233 C247.510917,177.099139 254.801533,177.092921 259.319824,179.198233 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M369.748011,238.254073 C374.252958,236.15498 381.543573,236.148762 386.061865,238.254073 L497.04937,289.96904 C501.554316,292.068134 501.567661,295.465218 497.04937,297.57053 L386.061865,349.285497 C381.556918,351.38459 374.266303,351.390808 369.748011,349.285497 L258.760506,297.57053 C254.25556,295.471436 254.242215,292.074352 258.760506,289.96904 L369.748011,238.254073 L369.748011,238.254073 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M242.845164,60.1548464 C247.35011,58.055753 254.640725,58.0495346 259.159017,60.1548464 L370.146522,111.869813 C374.651468,113.968907 374.664814,117.365991 370.146522,119.471303 L259.159017,171.18627 C254.654071,173.285363 247.363455,173.291581 242.845164,171.18627 L131.857659,119.471303 C127.352713,117.372209 127.339367,113.975125 131.857659,111.869813 L242.845164,60.1548464 L242.845164,60.1548464 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M371.027455,119.881776 C375.532401,117.782683 382.823016,117.776464 387.341308,119.881776 L498.328813,171.596743 C502.833759,173.695836 502.847104,177.092921 498.328813,179.198233 L387.341308,230.913199 C382.836362,233.012293 375.545746,233.018511 371.027455,230.913199 L260.039949,179.198233 C255.535003,177.099139 255.521658,173.702055 260.039949,171.596743 L371.027455,119.881776 L371.027455,119.881776 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M514.083348,178.937617 L625.070853,230.652584 C629.575799,232.751677 629.589145,236.148762 625.070853,238.254073 L514.083348,289.96904 C509.578402,292.068134 502.287786,292.074352 497.769495,289.96904 L386.78199,238.254073 C382.277044,236.15498 382.263698,232.757895 386.78199,230.652584 L497.769495,178.937617 C502.274441,176.838523 509.565056,176.832305 514.083348,178.937617 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M371.352206,1.57665281 C375.857152,-0.522440614 383.147768,-0.528658958 387.666059,1.57665281 L498.653564,53.2916196 C503.158511,55.390713 503.171856,58.7877976 498.653564,60.8931093 L387.666059,112.608076 C383.161113,114.70717 375.870498,114.713388 371.352206,112.608076 L260.364701,60.8931093 C255.859755,58.7940159 255.84641,55.3969314 260.364701,53.2916196 L371.352206,1.57665281 L371.352206,1.57665281 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M497.472368,59.7726139 C501.977314,57.6735205 509.267929,57.6673022 513.786221,59.7726139 L624.773726,111.487581 C629.278672,113.586674 629.292017,116.983759 624.773726,119.08907 L513.786221,170.804037 C509.281275,172.903131 501.990659,172.909349 497.472368,170.804037 L386.484862,119.08907 C381.979916,116.989977 381.966571,113.592893 386.484862,111.487581 L497.472368,59.7726139 L497.472368,59.7726139 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                <path d="M624.214408,118.828455 C628.719354,116.729361 636.009969,116.723143 640.528261,118.828455 L751.515766,170.543421 C756.020712,172.642515 756.034058,176.039599 751.515766,178.144911 L640.528261,229.859878 C636.023315,231.958971 628.732699,231.96519 624.214408,229.859878 L513.226903,178.144911 C508.721957,176.045818 508.708611,172.648733 513.226903,170.543421 L624.214408,118.828455 L624.214408,118.828455 Z" id="Rectangle-1" fill="#807C7C"></path>
                            </g>
                            <g id="Group" transform="translate(378.658283, 475.410522) rotate(-302.000000) translate(-378.658283, -475.410522) translate(31.658283, 245.910522)" stroke="#090909" strokeWidth="4">
                                <path d="M105.556184,156.051819 C109.691741,153.31173 116.384553,153.303612 120.532361,156.051819 L222.4193,223.558879 C226.554857,226.298968 226.567108,230.733414 222.4193,233.48162 L120.532361,300.98868 C116.396804,303.728769 109.703992,303.736887 105.556184,300.98868 L3.66924474,233.48162 C-0.466312448,230.741531 -0.478563602,226.307086 3.66924474,223.558879 L105.556184,156.051819 L105.556184,156.051819 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M223.080377,233.919629 C227.215935,231.17954 233.908746,231.171423 238.056555,233.919629 L339.943494,301.426689 C344.079051,304.166779 344.091302,308.601224 339.943494,311.34943 L238.056555,378.85649 C233.920998,381.59658 227.228186,381.604697 223.080377,378.85649 L121.193439,311.34943 C117.057881,308.609341 117.04563,304.174896 121.193439,301.426689 L223.080377,233.919629 L223.080377,233.919629 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M339.430038,311.009231 C343.565595,308.269141 350.258407,308.261024 354.406215,311.009231 L456.293154,378.516291 C460.428711,381.25638 460.440962,385.690825 456.293154,388.439032 L354.406215,455.946092 C350.270658,458.686181 343.577846,458.694298 339.430038,455.946092 L237.543099,388.439032 C233.407542,385.698942 233.395291,381.264497 237.543099,378.516291 L339.430038,311.009231 L339.430038,311.009231 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M222.932756,78.5242084 C227.068313,75.7841191 233.761125,75.7760019 237.908933,78.5242084 L339.795872,146.031268 C343.931429,148.771358 343.94368,153.205803 339.795872,155.954009 L237.908933,223.46107 C233.773376,226.201159 227.080564,226.209276 222.932756,223.46107 L121.045817,155.954009 C116.91026,153.21392 116.898008,148.779475 121.045817,146.031268 L222.932756,78.5242084 L222.932756,78.5242084 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M340.604571,156.489828 C344.740128,153.749739 351.43294,153.741622 355.580749,156.489828 L457.467687,223.996888 C461.603245,226.736977 461.615496,231.171423 457.467687,233.919629 L355.580749,301.426689 C351.445191,304.166779 344.752379,304.174896 340.604571,301.426689 L238.717632,233.919629 C234.582075,231.17954 234.569824,226.745095 238.717632,223.996888 L340.604571,156.489828 L340.604571,156.489828 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M456.954231,233.57943 C461.089789,230.83934 467.7826,230.831223 471.930409,233.57943 L573.817348,301.08649 C577.952905,303.826579 577.965156,308.261024 573.817348,311.009231 L471.930409,378.516291 C467.794852,381.25638 461.10204,381.264497 456.954231,378.516291 L355.067293,311.009231 C350.931735,308.269141 350.919484,303.834696 355.067293,301.08649 L456.954231,233.57943 L456.954231,233.57943 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M340.902694,2.05811204 C345.038252,-0.681977232 351.731063,-0.690094459 355.878872,2.05811204 L457.76581,69.5651721 C461.901368,72.3052614 461.913619,76.7397066 457.76581,79.4879131 L355.878872,146.994973 C351.743315,149.735062 345.050503,149.74318 340.902694,146.994973 L239.015756,79.4879131 C234.880198,76.7478239 234.867947,72.3133786 239.015756,69.5651721 L340.902694,2.05811204 L340.902694,2.05811204 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M456.681467,78.0252544 C460.817025,75.2851651 467.509836,75.2770479 471.657645,78.0252544 L573.544584,145.532314 C577.680141,148.272404 577.692392,152.706849 573.544584,155.455055 L471.657645,222.962116 C467.522088,225.702205 460.829276,225.710322 456.681467,222.962116 L354.794529,155.455055 C350.658971,152.714966 350.64672,148.280521 354.794529,145.532314 L456.681467,78.0252544 L456.681467,78.0252544 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M573.031128,155.114856 C577.166685,152.374767 583.859497,152.366649 588.007305,155.114856 L689.894244,222.621916 C694.029801,225.362005 694.042052,229.79645 689.894244,232.544657 L588.007305,300.051717 C583.871748,302.791806 577.178936,302.799924 573.031128,300.051717 L471.144189,232.544657 C467.008632,229.804568 466.996381,225.370122 471.144189,222.621916 L573.031128,155.114856 L573.031128,155.114856 Z" id="Rectangle-1" fill="#807C7C"></path>
                            </g>
                            <g id="Group" transform="translate(763.047754, 472.485332) scale(-1, 1) rotate(-303.000000) translate(-763.047754, -472.485332) translate(409.547754, 250.485332)">
                                <path d="M107.667307,150.95393 C111.885576,148.303353 118.712244,148.295501 122.943008,150.95393 L226.867686,216.255674 C231.085954,218.90625 231.09845,223.195831 226.867686,225.85426 L122.943008,291.156004 C118.72474,293.80658 111.898072,293.814432 107.667307,291.156004 L3.74262964,225.85426 C-0.475638697,223.203683 -0.488134874,218.914102 3.74262964,216.255674 L107.667307,150.95393 L107.667307,150.95393 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M227.541985,226.27796 C231.760253,223.627384 238.586921,223.619532 242.817686,226.27796 L346.742363,291.579704 C350.960632,294.230281 350.973128,298.519862 346.742363,301.17829 L242.817686,366.480034 C238.599417,369.130611 231.772749,369.138463 227.541985,366.480034 L123.617307,301.17829 C119.399039,298.527714 119.386543,294.238133 123.617307,291.579704 L227.541985,226.27796 L227.541985,226.27796 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M346.218638,300.849204 C350.436907,298.198628 357.263575,298.190776 361.494339,300.849204 L465.419017,366.150948 C469.637285,368.801525 469.649781,373.091106 465.419017,375.749534 L361.494339,441.051279 C357.276071,443.701855 350.449403,443.709707 346.218638,441.051279 L242.293961,375.749534 C238.075692,373.098958 238.063196,368.809377 242.293961,366.150948 L346.218638,300.849204 L346.218638,300.849204 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M227.391411,75.9589853 C231.609679,73.3084091 238.436347,73.300557 242.667112,75.9589853 L346.591789,141.26073 C350.810058,143.911306 350.822554,148.200887 346.591789,150.859315 L242.667112,216.16106 C238.448843,218.811636 231.622175,218.819488 227.391411,216.16106 L123.466733,150.859315 C119.248465,148.208739 119.235969,143.919158 123.466733,141.26073 L227.391411,75.9589853 L227.391411,75.9589853 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M347.416663,151.37763 C351.634931,148.727054 358.461599,148.719202 362.692363,151.37763 L466.617041,216.679374 C470.835309,219.329951 470.847806,223.619532 466.617041,226.27796 L362.692363,291.579704 C358.474095,294.230281 351.647427,294.238133 347.416663,291.579704 L243.491985,226.27796 C239.273717,223.627384 239.26122,219.337803 243.491985,216.679374 L347.416663,151.37763 L347.416663,151.37763 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M466.093316,225.948874 C470.311584,223.298298 477.138252,223.290446 481.369017,225.948874 L585.293695,291.250618 C589.511963,293.901195 589.524459,298.190776 585.293695,300.849204 L481.369017,366.150948 C477.150749,368.801525 470.324081,368.809377 466.093316,366.150948 L362.168638,300.849204 C357.95037,298.198628 357.937874,293.909047 362.168638,291.250618 L466.093316,225.948874 L466.093316,225.948874 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M347.720748,1.99087779 C351.939017,-0.65969845 358.765685,-0.667550504 362.996449,1.99087779 L466.921127,67.2926222 C471.139395,69.9431984 471.151891,74.2327796 466.921127,76.8912079 L362.996449,142.192952 C358.778181,144.843529 351.951513,144.851381 347.720748,142.192952 L243.796071,76.8912079 C239.577802,74.2406316 239.565306,69.9510505 243.796071,67.2926222 L347.720748,1.99087779 L347.720748,1.99087779 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M465.815097,75.4763311 C470.033365,72.8257549 476.860033,72.8179028 481.090798,75.4763311 L585.015475,140.778076 C589.233744,143.428652 589.24624,147.718233 585.015475,150.376661 L481.090798,215.678406 C476.872529,218.328982 470.045861,218.336834 465.815097,215.678406 L361.890419,150.376661 C357.672151,147.726085 357.659655,143.436504 361.890419,140.778076 L465.815097,75.4763311 L465.815097,75.4763311 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M584.49175,150.047575 C588.710019,147.396999 595.536687,147.389147 599.767451,150.047575 L703.692129,215.34932 C707.910397,217.999896 707.922893,222.289477 703.692129,224.947905 L599.767451,290.24965 C595.549183,292.900226 588.722515,292.908078 584.49175,290.24965 L480.567073,224.947905 C476.348804,222.297329 476.336308,218.007748 480.567073,215.34932 L584.49175,150.047575 L584.49175,150.047575 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                            </g>
                        </g>
                    </g>
                    <g id="arrow" transform="translate(1697.125370, 1161.656494) scale(-1, 1) rotate(-313.000000) translate(-1697.125370, -1161.656494) translate(1474.125370, 929.156494)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square" >
                        <path d="M446,-5.55111512e-17 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="1" transform="translate(652.000000, 767.000000)" strokeWidth="4" stroke="#090909" >
                        <g id="1flower-+-1flower">
                            <g id="1flower">
                                <g>
                                    <g id="Group" transform="translate(392.864200, 496.947767) rotate(-302.000000) translate(-392.864200, -496.947767) translate(30.864200, 260.447767)" fill="#807C7C">
                                        <path d="M110.274907,160.867651 C114.595337,158.043002 121.587341,158.034634 125.92057,160.867651 L232.362204,230.458011 C236.682635,233.282661 236.695434,237.853955 232.362204,240.686973 L125.92057,310.277332 C121.60014,313.101982 114.608136,313.11035 110.274907,310.277332 L3.83327255,240.686973 C-0.487158211,237.862323 -0.499957034,233.291029 3.83327255,230.458011 L110.274907,160.867651 L110.274907,160.867651 Z" id="Rectangle-1"></path>
                                        <path d="M233.052834,241.138499 C237.373265,238.313849 244.365269,238.305481 248.698498,241.138499 L355.140132,310.728859 C359.460563,313.553508 359.473362,318.124803 355.140132,320.95782 L248.698498,390.54818 C244.378067,393.37283 237.386064,393.381197 233.052834,390.54818 L126.6112,320.95782 C122.29077,318.13317 122.277971,313.561876 126.6112,310.728859 L233.052834,241.138499 L233.052834,241.138499 Z" id="Rectangle-1"></path>
                                        <path d="M354.603723,320.607122 C358.924154,317.782472 365.916157,317.774104 370.249387,320.607122 L476.691021,390.197481 C481.011452,393.022131 481.02425,397.593426 476.691021,400.426443 L370.249387,470.016803 C365.928956,472.841452 358.936953,472.84982 354.603723,470.016803 L248.162089,400.426443 C243.841658,397.601793 243.828859,393.030499 248.162089,390.197481 L354.603723,320.607122 L354.603723,320.607122 Z" id="Rectangle-1"></path>
                                        <path d="M232.898613,80.9475024 C237.219044,78.1228527 244.211048,78.1144849 248.544277,80.9475024 L354.985911,150.537862 C359.306342,153.362512 359.319141,157.933806 354.985911,160.766824 L248.544277,230.357183 C244.223846,233.181833 237.231843,233.190201 232.898613,230.357183 L126.456979,160.766824 C122.136549,157.942174 122.12375,153.37088 126.456979,150.537862 L232.898613,80.9475024 L232.898613,80.9475024 Z" id="Rectangle-1"></path>
                                        <path d="M355.830762,161.319178 C360.151193,158.494528 367.143196,158.48616 371.476426,161.319178 L477.91806,230.909537 C482.238491,233.734187 482.25129,238.305481 477.91806,241.138499 L371.476426,310.728859 C367.155995,313.553508 360.163992,313.561876 355.830762,310.728859 L249.389128,241.138499 C245.068697,238.313849 245.055899,233.742555 249.389128,230.909537 L355.830762,161.319178 L355.830762,161.319178 Z" id="Rectangle-1"></path>
                                        <path d="M477.381651,240.787801 C481.702082,237.963151 488.694085,237.954783 493.027315,240.787801 L599.468949,310.37816 C603.789379,313.20281 603.802178,317.774104 599.468949,320.607122 L493.027315,390.197481 C488.706884,393.022131 481.71488,393.030499 477.381651,390.197481 L370.940017,320.607122 C366.619586,317.782472 366.606787,313.211178 370.940017,310.37816 L477.381651,240.787801 L477.381651,240.787801 Z" id="Rectangle-1"></path>
                                        <path d="M356.142212,2.12162634 C360.462643,-0.70302337 367.454647,-0.711391099 371.787876,2.12162634 L478.22951,71.711986 C482.549941,74.5366358 482.56274,79.1079301 478.22951,81.9409475 L371.787876,151.531307 C367.467446,154.355957 360.475442,154.364325 356.142212,151.531307 L249.700579,81.9409475 C245.380148,79.1162978 245.367349,74.5450035 249.700579,71.711986 L356.142212,2.12162634 L356.142212,2.12162634 Z" id="Rectangle-1"></path>
                                        <path d="M477.096693,80.4331504 C481.417124,77.6085007 488.409128,77.600133 492.742357,80.4331504 L599.183991,150.02351 C603.504422,152.84816 603.517221,157.419454 599.183991,160.252472 L492.742357,229.842831 C488.421926,232.667481 481.429923,232.675849 477.096693,229.842831 L370.655059,160.252472 C366.334629,157.427822 366.32183,152.856528 370.655059,150.02351 L477.096693,80.4331504 L477.096693,80.4331504 Z" id="Rectangle-1"></path>
                                        <path d="M598.647582,159.901773 C602.968013,157.077124 609.960016,157.068756 614.293246,159.901773 L720.73488,229.492133 C725.055311,232.316783 725.068109,236.888077 720.73488,239.721094 L614.293246,309.311454 C609.972815,312.136104 602.980812,312.144472 598.647582,309.311454 L492.205948,239.721094 C487.885517,236.896445 487.872718,232.32515 492.205948,229.492133 L598.647582,159.901773 L598.647582,159.901773 Z" id="Rectangle-1"></path>
                                    </g>
                                    <g id="Group" transform="translate(794.232769, 494.379537) scale(-1, 1) rotate(-303.000000) translate(-794.232769, -494.379537) translate(424.732769, 265.879537)" fill="#807C7C">
                                        <path d="M112.480405,155.612439 C116.887244,152.880065 124.019088,152.87197 128.438982,155.612439 L237.009448,222.929426 C241.416288,225.6618 241.429343,230.08376 237.009448,232.824228 L128.438982,300.141215 C124.032142,302.87359 116.900299,302.881684 112.480405,300.141215 L3.909938,232.824228 C-0.496901376,230.091854 -0.509956175,225.669895 3.909938,222.929426 L112.480405,155.612439 L112.480405,155.612439 Z" id="Rectangle-1"></path>
                                        <path d="M237.713891,233.261004 C242.12073,230.52863 249.252574,230.520536 253.672468,233.261004 L362.242935,300.577991 C366.649774,303.310365 366.662829,307.732325 362.242935,310.472794 L253.672468,377.789781 C249.265629,380.522155 242.133785,380.530249 237.713891,377.789781 L129.143424,310.472794 C124.736585,307.740419 124.72353,303.31846 129.143424,300.577991 L237.713891,233.261004 L237.713891,233.261004 Z" id="Rectangle-1"></path>
                                        <path d="M361.695797,310.133552 C366.102637,307.401178 373.23448,307.393083 377.654375,310.133552 L486.224841,377.450539 C490.631681,380.182913 490.644735,384.604873 486.224841,387.345341 L377.654375,454.662328 C373.247535,457.394703 366.115692,457.402797 361.695797,454.662328 L253.125331,387.345341 C248.718491,384.612967 248.705437,380.191008 253.125331,377.450539 L361.695797,310.133552 L361.695797,310.133552 Z" id="Rectangle-1"></path>
                                        <path d="M237.556586,78.3031153 C241.963425,75.5707411 249.095269,75.5626467 253.515163,78.3031153 L362.085629,145.620102 C366.492469,148.352477 366.505524,152.774436 362.085629,155.514905 L253.515163,222.831892 C249.108323,225.564266 241.97648,225.57236 237.556586,222.831892 L128.986119,155.514905 C124.57928,152.782531 124.566225,148.360571 128.986119,145.620102 L237.556586,78.3031153 L237.556586,78.3031153 Z" id="Rectangle-1"></path>
                                        <path d="M362.947377,156.049215 C367.354217,153.316841 374.48606,153.308746 378.905954,156.049215 L487.476421,223.366202 C491.88326,226.098576 491.896315,230.520536 487.476421,233.261004 L378.905954,300.577991 C374.499115,303.310365 367.367272,303.31846 362.947377,300.577991 L254.376911,233.261004 C249.970071,230.52863 249.957017,226.10667 254.376911,223.366202 L362.947377,156.049215 L362.947377,156.049215 Z" id="Rectangle-1"></path>
                                        <path d="M486.929284,232.921763 C491.336123,230.189388 498.467967,230.181294 502.887861,232.921763 L611.458328,300.238749 C615.865167,302.971124 615.878222,307.393083 611.458328,310.133552 L502.887861,377.450539 C498.481022,380.182913 491.349178,380.191008 486.929284,377.450539 L378.358817,310.133552 C373.951978,307.401178 373.938923,302.979218 378.358817,300.238749 L486.929284,232.921763 L486.929284,232.921763 Z" id="Rectangle-1"></path>
                                        <path d="M363.265057,2.05231721 C367.671896,-0.680057055 374.80374,-0.688151427 379.223634,2.05231721 L487.7941,69.3693042 C492.20094,72.1016784 492.213995,76.5236381 487.7941,79.2641067 L379.223634,146.581094 C374.816794,149.313468 367.684951,149.321562 363.265057,146.581094 L254.69459,79.2641067 C250.287751,76.5317324 250.274696,72.1097728 254.69459,69.3693042 L363.265057,2.05231721 L363.265057,2.05231721 Z" id="Rectangle-1"></path>
                                        <path d="M486.638627,77.8055662 C491.045467,75.0731919 498.17731,75.0650976 502.597204,77.8055662 L611.167671,145.122553 C615.57451,147.854927 615.587565,152.276887 611.167671,155.017356 L502.597204,222.334343 C498.190365,225.066717 491.058521,225.074811 486.638627,222.334343 L378.068161,155.017356 C373.661321,152.284981 373.648266,147.863022 378.068161,145.122553 L486.638627,77.8055662 L486.638627,77.8055662 Z" id="Rectangle-1"></path>
                                        <path d="M610.620534,154.678114 C615.027373,151.94574 622.159217,151.937645 626.579111,154.678114 L735.149577,221.995101 C739.556417,224.727475 739.569472,229.149435 735.149577,231.889903 L626.579111,299.20689 C622.172271,301.939265 615.040428,301.947359 610.620534,299.20689 L502.050067,231.889903 C497.643228,229.157529 497.630173,224.73557 502.050067,221.995101 L610.620534,154.678114 L610.620534,154.678114 Z" id="Rectangle-1"></path>
                                    </g>
                                    <g id="Group" transform="translate(204.007864, 0.000000)">
                                        <path d="M118.851844,126.037472 C123.508308,123.824399 131.044134,123.817843 135.714392,126.037472 L250.43482,180.560509 C255.091284,182.773581 255.105079,186.355124 250.43482,188.574752 L135.714392,243.097789 C131.057928,245.310861 123.522102,245.317417 118.851844,243.097789 L4.13141597,188.574752 C-0.525048295,186.36168 -0.538842581,182.780137 4.13141597,180.560509 L118.851844,126.037472 L118.851844,126.037472 Z" id="Rectangle-1" fill="#807C7C"></path>
                                        <path d="M268.041715,188.928516 L382.762142,243.451554 C387.418607,245.664626 387.432401,249.246168 382.762142,251.465797 L268.041715,305.988834 C263.38525,308.201906 255.849424,308.208462 251.179166,305.988834 L136.458738,251.465797 C131.802274,249.252724 131.78848,245.671182 136.458738,243.451554 L251.179166,188.928516 C255.83563,186.715444 263.371456,186.708888 268.041715,188.928516 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M382.184013,251.19103 C386.840477,248.977957 394.376303,248.971401 399.046561,251.19103 L513.766989,305.714067 C518.423453,307.927139 518.437248,311.508682 513.766989,313.72831 L399.046561,368.251347 C394.390097,370.464419 386.854271,370.470975 382.184013,368.251347 L267.463585,313.72831 C262.807121,311.515238 262.793326,307.933695 267.463585,305.714067 L382.184013,251.19103 L382.184013,251.19103 Z" id="Rectangle-1" fill="#807C7C"></path>
                                        <path d="M251.01295,63.421194 C255.669414,61.2081219 263.20524,61.2015659 267.875499,63.421194 L382.595926,117.944231 C387.252391,120.157303 387.266185,123.738846 382.595926,125.958474 L267.875499,180.481512 C263.219035,182.694584 255.683209,182.70114 251.01295,180.481512 L136.292522,125.958474 C131.636058,123.745402 131.622264,120.163859 136.292522,117.944231 L251.01295,63.421194 L251.01295,63.421194 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M383.506488,126.391236 C388.162952,124.178164 395.698778,124.171608 400.369037,126.391236 L515.089465,180.914273 C519.745929,183.127345 519.759723,186.708888 515.089465,188.928516 L400.369037,243.451554 C395.712573,245.664626 388.176747,245.671182 383.506488,243.451554 L268.78606,188.928516 C264.129596,186.715444 264.115802,183.133901 268.78606,180.914273 L383.506488,126.391236 L383.506488,126.391236 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                        <path d="M531.373884,188.653749 L646.094311,243.176787 C650.750776,245.389859 650.76457,248.971401 646.094311,251.19103 L531.373884,305.714067 C526.717419,307.927139 519.181593,307.933695 514.511335,305.714067 L399.790907,251.19103 C395.134443,248.977957 395.120649,245.396415 399.790907,243.176787 L514.511335,188.653749 C519.167799,186.440677 526.703625,186.434121 531.373884,188.653749 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M383.842162,1.66226347 C388.498627,-0.550808613 396.034453,-0.557364607 400.704711,1.66226347 L515.425139,56.1853009 C520.081603,58.398373 520.095397,61.9799158 515.425139,64.1995438 L400.704711,118.722581 C396.048247,120.935653 388.512421,120.942209 383.842162,118.722581 L269.121735,64.1995438 C264.46527,61.9864718 264.451476,58.404929 269.121735,56.1853009 L383.842162,1.66226347 L383.842162,1.66226347 Z" id="Rectangle-1" fill="#807C7C"></path>
                                        <path d="M514.204214,63.0182067 C518.860678,60.8051346 526.396504,60.7985786 531.066763,63.0182067 L645.78719,117.541244 C650.443655,119.754316 650.457449,123.335859 645.78719,125.555487 L531.066763,180.078524 C526.410298,182.291597 518.874472,182.298153 514.204214,180.078524 L399.483786,125.555487 C394.827322,123.342415 394.813528,119.760872 399.483786,117.541244 L514.204214,63.0182067 L514.204214,63.0182067 Z" id="Rectangle-1" fill="#FFFFFF"></path>
                                        <path d="M645.209061,125.28072 C649.865525,123.067648 657.401351,123.061092 662.071609,125.28072 L776.792037,179.803757 C781.448501,182.016829 781.462296,185.598372 776.792037,187.818 L662.071609,242.341038 C657.415145,244.55411 649.879319,244.560666 645.209061,242.341038 L530.488633,187.818 C525.832169,185.604928 525.818374,182.023385 530.488633,179.803757 L645.209061,125.28072 L645.209061,125.28072 Z" id="Rectangle-1" fill="#807C7C"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="arrow" transform="translate(1450.000000, 386.000000)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square" >
                        <path d="M446,-5.55111512e-17 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="cube" transform="translate(1232.000000, 0.000000)" >
                        <g id="Group" transform="translate(212.257453, 0.000000)">
                            <path d="M121.677228,125.852384 C126.444387,123.642562 134.159357,123.636016 138.940639,125.852384 L256.388237,180.295354 C261.155397,182.505176 261.169519,186.081459 256.388237,188.297828 L138.940639,242.740798 C134.17348,244.95062 126.458509,244.957166 121.677228,242.740798 L4.22962933,188.297828 C-0.537529912,186.088006 -0.551652121,182.511723 4.22962933,180.295354 L121.677228,125.852384 L121.677228,125.852384 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                            <path d="M274.413689,188.651073 L391.861287,243.094043 C396.628447,245.303865 396.642569,248.880148 391.861287,251.096516 L274.413689,305.539486 C269.646529,307.749308 261.931559,307.755855 257.150278,305.539486 L139.702679,251.096516 C134.93552,248.886694 134.921398,245.310411 139.702679,243.094043 L257.150278,188.651073 C261.917437,186.441251 269.632407,186.434704 274.413689,188.651073 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M391.269414,250.822153 C396.036573,248.612331 403.751543,248.605784 408.532825,250.822153 L525.980424,305.265123 C530.747583,307.474945 530.761705,311.051228 525.980424,313.267597 L408.532825,367.710566 C403.765666,369.920389 396.050696,369.926935 391.269414,367.710566 L273.821815,313.267597 C269.054656,311.057775 269.040534,307.481491 273.821815,305.265123 L391.269414,250.822153 L391.269414,250.822153 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                            <path d="M256.980111,63.3280593 C261.74727,61.1182371 269.46224,61.1116908 274.243521,63.3280593 L391.69112,117.771029 C396.458279,119.980851 396.472402,123.557135 391.69112,125.773503 L274.243521,180.216473 C269.476362,182.426295 261.761392,182.432841 256.980111,180.216473 L139.532512,125.773503 C134.765353,123.563681 134.751231,119.987398 139.532512,117.771029 L256.980111,63.3280593 L256.980111,63.3280593 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF9F00"></path>
                            <path d="M392.623328,126.205629 C397.390487,123.995807 405.105457,123.989261 409.886739,126.205629 L527.334337,180.648599 C532.101497,182.858421 532.115619,186.434704 527.334337,188.651073 L409.886739,243.094043 C405.119579,245.303865 397.404609,245.310411 392.623328,243.094043 L275.175729,188.651073 C270.40857,186.441251 270.394448,182.864967 275.175729,180.648599 L392.623328,126.205629 L392.623328,126.205629 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                            <path d="M544.005875,188.376709 L661.453474,242.819679 C666.220633,245.029501 666.234755,248.605784 661.453474,250.822153 L544.005875,305.265123 C539.238716,307.474945 531.523746,307.481491 526.742464,305.265123 L409.294865,250.822153 C404.527706,248.612331 404.513584,245.036048 409.294865,242.819679 L526.742464,188.376709 C531.509623,186.166887 539.224593,186.160341 544.005875,188.376709 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                            <path d="M392.966982,1.65982242 C397.734141,-0.549999745 405.449111,-0.556546111 410.230393,1.65982242 L527.677991,56.1027922 C532.445151,58.3126144 532.459273,61.8888976 527.677991,64.1052661 L410.230393,118.548236 C405.463233,120.758058 397.748263,120.764604 392.966982,118.548236 L275.519383,64.1052661 C270.752224,61.895444 270.738102,58.3191607 275.519383,56.1027922 L392.966982,1.65982242 L392.966982,1.65982242 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                            <path d="M526.428042,62.9256638 C531.195202,60.7158416 538.910172,60.7092953 543.691453,62.9256638 L661.139052,117.368634 C665.906211,119.578456 665.920333,123.154739 661.139052,125.371108 L543.691453,179.814077 C538.924294,182.023899 531.209324,182.030446 526.428042,179.814077 L408.980444,125.371108 C404.213284,123.161285 404.199162,119.585002 408.980444,117.368634 L526.428042,62.9256638 L526.428042,62.9256638 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFFFF"></path>
                            <path d="M660.547178,125.096744 C665.314338,122.886922 673.029308,122.880375 677.810589,125.096744 L795.258188,179.539714 C800.025347,181.749536 800.039469,185.325819 795.258188,187.542188 L677.810589,241.985157 C673.04343,244.19498 665.32846,244.201526 660.547178,241.985157 L543.09958,187.542188 C538.33242,185.332366 538.318298,181.756082 543.09958,179.539714 L660.547178,125.096744 L660.547178,125.096744 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                        </g>
                        <g id="Group" transform="translate(403.092850, 502.986011) rotate(-302.000000) translate(-403.092850, -502.986011) translate(32.092850, 259.986011)">
                            <path d="M112.896397,165.534009 C117.319535,162.627424 124.477754,162.618813 128.913995,165.534009 L237.885994,237.143007 C242.309131,240.049592 242.322234,244.753488 237.885994,247.668684 L128.913995,319.277682 C124.490857,322.184267 117.332638,322.192878 112.896397,319.277682 L3.92439834,247.668684 C-0.498739094,244.762099 -0.511842174,240.058203 3.92439834,237.143007 L112.896397,165.534009 L112.896397,165.534009 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                            <path d="M238.593041,248.133308 C243.016179,245.226723 250.174399,245.218112 254.610639,248.133308 L363.582638,319.742306 C368.005775,322.648891 368.018878,327.352787 363.582638,330.267983 L254.610639,401.876981 C250.187502,404.783566 243.029282,404.792177 238.593041,401.876981 L129.621043,330.267983 C125.197905,327.361397 125.184802,322.657502 129.621043,319.742306 L238.593041,248.133308 L238.593041,248.133308 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                            <path d="M379.051075,329.907112 L488.023073,401.516109 C492.446211,404.422695 492.459314,409.126591 488.023073,412.041787 L379.051075,483.650784 C374.627937,486.55737 367.469718,486.56598 363.033477,483.650784 L254.061478,412.041787 C249.638341,409.135201 249.625238,404.431305 254.061478,401.516109 L363.033477,329.907112 C367.456614,327.000526 374.614834,326.991916 379.051075,329.907112 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M254.452752,83.2955818 L363.424751,154.904579 C367.847888,157.811165 367.860991,162.515061 363.424751,165.430257 L254.452752,237.039254 C250.029614,239.94584 242.871395,239.95445 238.435154,237.039254 L129.463156,165.430257 C125.040018,162.523671 125.026915,157.819775 129.463156,154.904579 L238.435154,83.2955818 C242.858292,80.3889962 250.016511,80.3803857 254.452752,83.2955818 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M364.289686,165.998633 C368.712823,163.092048 375.871043,163.083437 380.307283,165.998633 L489.279282,237.607631 C493.70242,240.514216 493.715523,245.218112 489.279282,248.133308 L380.307283,319.742306 C375.884146,322.648891 368.725926,322.657502 364.289686,319.742306 L255.317687,248.133308 C250.89455,245.226723 250.881446,240.522827 255.317687,237.607631 L364.289686,165.998633 L364.289686,165.998633 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M488.730121,247.772437 C493.153259,244.865851 500.311478,244.857241 504.747719,247.772437 L613.719718,319.381434 C618.142855,322.28802 618.155958,326.991916 613.719718,329.907112 L504.747719,401.516109 C500.324581,404.422695 493.166362,404.431305 488.730121,401.516109 L379.758123,329.907112 C375.334985,327.000526 375.321882,322.29663 379.758123,319.381434 L488.730121,247.772437 L488.730121,247.772437 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                            <path d="M364.60854,2.18316928 C369.031677,-0.723416275 376.189897,-0.732026729 380.626138,2.18316928 L489.598136,73.7921668 C494.021274,76.6987523 494.034377,81.4026482 489.598136,84.3178442 L380.626138,155.926842 C376.203,158.833427 369.04478,158.842038 364.60854,155.926842 L255.636541,84.3178442 C251.213404,81.4112586 251.200301,76.7073628 255.636541,73.7921668 L364.60854,2.18316928 L364.60854,2.18316928 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF9F00"></path>
                            <path d="M488.43839,82.7663098 C492.861527,79.8597242 500.019747,79.8511138 504.455987,82.7663098 L613.427986,154.375307 C617.851124,157.281893 617.864227,161.985789 613.427986,164.900985 L504.455987,236.509982 C500.03285,239.416568 492.87463,239.425178 488.43839,236.509982 L379.466391,164.900985 C375.043254,161.994399 375.03015,157.290503 379.466391,154.375307 L488.43839,82.7663098 L488.43839,82.7663098 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M612.878825,164.540113 C617.301963,161.633528 624.460182,161.624917 628.896423,164.540113 L737.868422,236.149111 C742.291559,239.055697 742.304662,243.759592 737.868422,246.674788 L628.896423,318.283786 C624.473285,321.190371 617.315066,321.198982 612.878825,318.283786 L503.906827,246.674788 C499.483689,243.768203 499.470586,239.064307 503.906827,236.149111 L612.878825,164.540113 L612.878825,164.540113 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFFFF"></path>
                        </g>
                        <g id="Group" transform="translate(812.339647, 501.329906) scale(-1, 1) rotate(-303.000000) translate(-812.339647, -501.329906) translate(433.839647, 266.329906)">
                            <path d="M115.154325,160.059907 C119.665925,157.249441 126.967309,157.241115 131.492275,160.059907 L242.643713,229.300842 C247.155314,232.111308 247.168679,236.659649 242.643713,239.478442 L131.492275,308.719376 C126.980674,311.529843 119.67929,311.538169 115.154325,308.719376 L4.00288631,239.478442 C-0.508713875,236.667975 -0.522079017,232.119634 4.00288631,229.300842 L115.154325,160.059907 L115.154325,160.059907 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                            <path d="M243.364902,239.927701 C247.876502,237.117234 255.177886,237.108908 259.702852,239.927701 L370.854291,309.168635 C375.365891,311.979102 375.379256,316.527443 370.854291,319.346235 L259.702852,388.58717 C255.191252,391.397637 247.889868,391.405962 243.364902,388.58717 L132.213464,319.346235 C127.701863,316.535769 127.688498,311.987428 132.213464,309.168635 L243.364902,239.927701 L243.364902,239.927701 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFFFF"></path>
                            <path d="M370.294147,318.997298 C374.805747,316.186831 382.107131,316.178506 386.632096,318.997298 L497.783535,388.238232 C502.295135,391.048699 502.3085,395.59704 497.783535,398.415833 L386.632096,467.656767 C382.120496,470.467234 374.819112,470.475559 370.294147,467.656767 L259.142708,398.415833 C254.631108,395.605366 254.617742,391.057025 259.142708,388.238232 L370.294147,318.997298 L370.294147,318.997298 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            <path d="M259.541807,80.541051 L370.693246,149.781985 C375.204846,152.592452 375.218211,157.140793 370.693246,159.959586 L259.541807,229.20052 C255.030207,232.010987 247.728823,232.019312 243.203857,229.20052 L132.052419,159.959586 C127.540818,157.149119 127.527453,152.600778 132.052419,149.781985 L243.203857,80.541051 C247.715458,77.7305843 255.016842,77.7222586 259.541807,80.541051 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFFFF"></path>
                            <path d="M371.575479,160.509166 C376.08708,157.6987 383.388464,157.690374 387.913429,160.509166 L499.064868,229.750101 C503.576468,232.560567 503.589833,237.108908 499.064868,239.927701 L387.913429,309.168635 C383.401829,311.979102 376.100445,311.987428 371.575479,309.168635 L260.424041,239.927701 C255.912441,237.117234 255.899075,232.568893 260.424041,229.750101 L371.575479,160.509166 L371.575479,160.509166 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M498.504724,239.578763 C503.016324,236.768297 510.317708,236.759971 514.842673,239.578763 L625.994112,308.819698 C630.505712,311.630165 630.519077,316.178506 625.994112,318.997298 L514.842673,388.238232 C510.331073,391.048699 503.029689,391.057025 498.504724,388.238232 L387.353285,318.997298 C382.841685,316.186831 382.82832,311.63849 387.353285,308.819698 L498.504724,239.578763 L498.504724,239.578763 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                            <path d="M388.23866,2.11097329 L499.390099,71.3519078 C503.901699,74.1623744 503.915064,78.7107155 499.390099,81.5295078 L388.23866,150.770442 C383.72706,153.580909 376.425676,153.589235 371.900711,150.770442 L260.749272,81.5295078 C256.237672,78.7190412 256.224307,74.1707001 260.749272,71.3519078 L371.900711,2.11097329 C376.412311,-0.699493368 383.713695,-0.707819081 388.23866,2.11097329 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                            <path d="M514.545107,80.0292817 L625.696546,149.270216 C630.208146,152.080683 630.221511,156.629024 625.696546,159.447816 L514.545107,228.688751 C510.033507,231.499217 502.732123,231.507543 498.207158,228.688751 L387.055719,159.447816 C382.544119,156.63735 382.530753,152.089009 387.055719,149.270216 L498.207158,80.0292817 C502.718758,77.218815 510.020142,77.2104893 514.545107,80.0292817 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                            <path d="M641.474351,159.098879 L752.62579,228.339813 C757.13739,231.15028 757.150755,235.698621 752.62579,238.517413 L641.474351,307.758348 C636.962751,310.568814 629.661367,310.57714 625.136402,307.758348 L513.984963,238.517413 C509.473363,235.706947 509.459998,231.158606 513.984963,228.339813 L625.136402,159.098879 C629.648002,156.288412 636.949386,156.280086 641.474351,159.098879 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF9F00"></path>
                        </g>
                    </g>
                    <g id="arrow" transform="translate(768.023721, 2215.356476) scale(-1, 1) rotate(-1.000000) translate(-768.023721, -2215.356476) translate(545.023721, 1982.856476)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square" >
                        <path d="M446,-5.55111512e-17 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="5" transform="translate(0.000000, 1534.000000)" >
                        <g id="Group-+-Group-+-Group">
                            <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)">
                                <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                            </g>
                            <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" strokeWidth="4">
                                <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 L243.203857,81.5692346 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" fill="#3CFD03"></path>
                            </g>
                            <g id="Group" transform="translate(211.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                                <path d="M122.286376,127.553092 C127.0774,125.313408 134.830994,125.306773 139.636211,127.553092 L257.671783,182.731778 C262.462808,184.971462 262.477001,188.596074 257.671783,190.842393 L139.636211,246.021079 C134.845187,248.260763 127.091593,248.267398 122.286376,246.021079 L4.25080394,190.842393 C-0.540220925,188.602709 -0.554413834,184.978097 4.25080394,182.731778 L122.286376,127.553092 L122.286376,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M275.787474,191.200412 L393.823046,246.379097 C398.614071,248.618782 398.628264,252.243393 393.823046,254.489713 L275.787474,309.668398 C270.99645,311.908083 263.242856,311.914718 258.437639,309.668398 L140.402067,254.489713 C135.611042,252.250028 135.596849,248.625417 140.402067,246.379097 L258.437639,191.200412 C263.228663,188.960727 270.982257,188.954092 275.787474,191.200412 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                <path d="M393.22821,254.211641 C398.019235,251.971957 405.772828,251.965322 410.578046,254.211641 L528.613617,309.390327 C533.404642,311.630012 533.418835,315.254623 528.613617,317.500943 L410.578046,372.679628 C405.787021,374.919313 398.033427,374.925948 393.22821,372.679628 L275.192638,317.500943 C270.401613,315.261258 270.38742,311.636647 275.192638,309.390327 L393.22821,254.211641 L393.22821,254.211641 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M258.266619,64.1838439 C263.057644,61.9441593 270.811237,61.9375244 275.616455,64.1838439 L393.652027,119.362529 C398.443052,121.602214 398.457245,125.226826 393.652027,127.473145 L275.616455,182.651831 C270.82543,184.891515 263.071837,184.89815 258.266619,182.651831 L140.231048,127.473145 C135.440023,125.23346 135.42583,121.608849 140.231048,119.362529 L258.266619,64.1838439 L258.266619,64.1838439 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                <path d="M394.588902,127.911111 C399.379926,125.671426 407.13352,125.664791 411.938737,127.911111 L529.974309,183.089796 C534.765334,185.329481 534.779527,188.954092 529.974309,191.200412 L411.938737,246.379097 C407.147713,248.618782 399.394119,248.625417 394.588902,246.379097 L276.55333,191.200412 C271.762305,188.960727 271.748112,185.336116 276.55333,183.089796 L394.588902,127.911111 L394.588902,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                <path d="M546.729308,190.92234 L664.76488,246.101026 C669.555905,248.340711 669.570098,251.965322 664.76488,254.211641 L546.729308,309.390327 C541.938284,311.630012 534.18469,311.636647 529.379473,309.390327 L411.343901,254.211641 C406.552876,251.971957 406.538683,248.347345 411.343901,246.101026 L529.379473,190.92234 C534.170498,188.682656 541.924091,188.676021 546.729308,190.92234 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                <path d="M394.934276,1.68225245 C399.725301,-0.557432174 407.478894,-0.564067005 412.284112,1.68225245 L530.319683,56.860938 C535.110708,59.1006227 535.124901,62.7252341 530.319683,64.9715535 L412.284112,120.150239 C407.493087,122.389924 399.739494,122.396559 394.934276,120.150239 L276.898704,64.9715535 C272.107679,62.7318689 272.093487,59.1072575 276.898704,56.860938 L394.934276,1.68225245 L394.934276,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                                <path d="M529.063477,63.7760106 C533.854502,61.536326 541.608095,61.5296912 546.413313,63.7760106 L664.448884,118.954696 C669.239909,121.194381 669.254102,124.818992 664.448884,127.065312 L546.413313,182.243997 C541.622288,184.483682 533.868695,184.490317 529.063477,182.243997 L411.027905,127.065312 C406.23688,124.825627 406.222687,121.201016 411.027905,118.954696 L529.063477,63.7760106 L529.063477,63.7760106 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                <path d="M663.854048,126.787241 C668.645073,124.547556 676.398666,124.540921 681.203884,126.787241 L799.239455,181.965926 C804.03048,184.205611 804.044673,187.830222 799.239455,190.076542 L681.203884,245.255227 C676.412859,247.494912 668.659266,247.501547 663.854048,245.255227 L545.818476,190.076542 C541.027451,187.836857 541.013258,184.212246 545.818476,181.965926 L663.854048,126.787241 L663.854048,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                            </g>
                        </g>
                    </g>
                    <g id="arrow" transform="translate(1708.125370, 2806.656494) scale(-1, 1) rotate(-313.000000) translate(-1708.125370, -2806.656494) translate(1485.125370, 2574.156494)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square" >
                        <path d="M446,-5.55111512e-17 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="6" transform="translate(571.000000, 2377.000000)" >
                        <g id="шаги">
                            <g id="2-этап-+-Group">
                                <g id="Group">
                                    <g id="Group-+-Group-+-Group">
                                        <g id="Group" transform="translate(406.092850, 506.986011) rotate(-302.000000) translate(-406.092850, -506.986011) translate(35.092850, 260.986011)" stroke="#090909" strokeWidth="4">
                                            <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                            <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                            <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                            <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                            <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                            <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                            <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" fill="#FF9F00"></path>
                                        </g>
                                        <g id="Group" transform="translate(816.339647, 504.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -504.329906) translate(437.839647, 266.329906)">
                                            <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                            <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                                            <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                                            <path d="M243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 L243.203857,81.5692346 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                                            <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                                            <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                                            <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                            <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                                            <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#0000FF"></path>
                                        </g>
                                        <g id="Group" transform="translate(208.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                                            <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                            <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                            <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                            <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                            <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                            <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <path d="M413.5,334.5 L413.5,685.5" id="Line" stroke="#FFFFFF" strokeWidth="21" strokeLinecap="square"></path>
                            <path d="M820.5,334.5 L820.5,685.5" id="Line" stroke="#FFFFFF" strokeWidth="21" strokeLinecap="square"></path>
                        </g>
                    </g>
                    <g id="arrow" transform="translate(2287.000000, 3064.500000) rotate(-354.000000) translate(-2287.000000, -3064.500000) translate(2064.000000, 2832.000000)" stroke="#343A40" strokeWidth="27" fill="#343A40" strokeLinecap="square" >
                        <path d="M446,-5.55111512e-17 L46,411" id="Line"></path>
                        <polygon id="Triangle-41" transform="translate(45.783407, 417.621937) rotate(-139.000000) translate(-45.783407, -417.621937) " points="45.7834071 380.621937 74.2834071 454.621937 17.2834071 454.621937 "></polygon>
                    </g>
                    <g id="7" transform="translate(1834.000000, 2377.000000)" >
                        <g id="шаги-+-Group-+-Group-+-Group">
                            <g id="шаги">
                                <g id="2-этап-+-Group">
                                    <g id="Group">
                                        <g id="Group-+-Group-+-Group">
                                            <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)">
                                                <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                                <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FFFF00"></path>
                                                <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                            </g>
                                            <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)">
                                                <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#807C7C"></path>
                                                <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                                <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                                <path d="M259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                                <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                                <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                                <path d="M371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 L371.900711,2.13792189 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#FF0000"></path>
                                                <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                                <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1" stroke="#090909" strokeWidth="4" fill="#3CFD03"></path>
                                            </g>
                                            <g id="Group" transform="translate(208.257453, 0.000000)" stroke="#090909" strokeWidth="4">
                                                <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1" fill="#3CFD03"></path>
                                                <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1" fill="#807C7C"></path>
                                                <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1" fill="#FFFF00"></path>
                                                <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1" fill="#807C7C"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="8" transform="translate(1222.000000, 3189.000000)" >
                        <g id="2Group">
                            <g id="Group">
                                <g id="Group-+-Group-+-Group">
                                    <g id="Group" transform="translate(208.257453, 0.000000)" stroke="#090909" strokeWidth="4" fill="#FFFF00">
                                        <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1"></path>
                                        <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1"></path>
                                        <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1"></path>
                                        <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1"></path>
                                        <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1"></path>
                                        <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1"></path>
                                        <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1"></path>
                                        <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1"></path>
                                        <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1"></path>
                                    </g>
                                    <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)" stroke="#090909" strokeWidth="4" fill="#FF0000">
                                        <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1"></path>
                                        <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1"></path>
                                        <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1"></path>
                                        <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1"></path>
                                        <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1"></path>
                                        <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1"></path>
                                        <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1"></path>
                                        <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1"></path>
                                        <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1"></path>
                                    </g>
                                    <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" strokeWidth="4" fill="#3CFD03">
                                        <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1"></path>
                                        <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1"></path>
                                        <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1"></path>
                                        <path d="M259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 Z" id="Rectangle-1"></path>
                                        <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1"></path>
                                        <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1"></path>
                                        <path d="M388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 Z" id="Rectangle-1"></path>
                                        <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1"></path>
                                        <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default AllSchemesSvg;