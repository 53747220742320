import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from "gatsby"
// import containerStyles from './home.module.css';
// import containerStyles from '../H1home/homeh1.module.css';
import { useTranslation } from "react-i18next"
import {Container, Row, Col, Button } from 'react-bootstrap';
import GreenRobotSvg from './GreenRobotSvg'

import LayoutTitle from '../Layouts/LayoutTitle/LayoutTitle'
import AllSchemesSvg from './AllSchemesSvg'

const Home = () => {
  const { t } = useTranslation();
    return <div>
      <LayoutTitle>
        <h1>{t('home.h1_home')}</h1>
      </LayoutTitle>
      <div>
        <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          <Row className="justify-content-md-center" style={{ display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
            <Col lg={6} md ={6} xs={4} style={{  padding: '0 25 0 0' }}><GreenRobotSvg /> </Col>
            
            <Col lg={6} md ={6} xs={8} style={{  padding: '0'}} >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingLeft:'1rem'}} >
                <p><span>&#x2714; &emsp;</span>{t('home.Just_8_steps')}</p>
                <p><span>&#x2714; &emsp;</span>{t('home.video_instruction')}</p> 
                <p><span>&#x2714; &emsp;</span>{t('home.Simple_schemes_only')}</p> 
                <p><span>&#x2714; &emsp;</span>{t('home.Without_formulas')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className="curved-gray-bottom">
        <h4 style={{ textAlign: 'center', padding: '2rem', backgroundColor: "f6f2f2", margin: '4rem 0 2rem 0' }}>
          {t('home.layer_by_layer')}
        </h4>
      </section>
      <Container  style={{ maxWidth: '500px', textAlign: 'center' }}>
        <AllSchemesSvg />
      </Container>
      {/* Video */}
      <div className="container-iframe">
        <iframe
          style={{ display: 'block', padding: '1rem 0', margin: '0 auto'}}
          width="90%"
          height="600"
          title={t("home.videoTitle")}
          src={t("home.videoUrl")}
          frameBorder="0"
          // startSeconds="15"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
        
      </div>
      <Link to="/step-1/">
        <Button variant="success" style={{ display: 'block', margin: '0 auto', marginTop: '2rem', marginBottom: '2rem', padding: "1rem", textAlign: 'center', letterSpacing: '3px'}}>
          {t('home.button_start')}
        </Button>
      </Link>
    </div> 
}
export default Home;

// const customProps = {
//   localeKey: 'home', // same as file name in src/i18n/translations/your-lang/index.js
// };
// Home.propTypes = {
//   current_locale: PropTypes.string,
// };

// Home.defaultProps = {
//   current_locale: ''
// };
