import React from 'react';

const GreenRobotSvg = () => {
    return <div style={{ width: '100%'}}>
        <svg viewBox="0 0 1263 1141" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                <g id="Group-+-Group-+-Group-+-cube-+-green_robot" transform="translate(-1.000000, 0.000000)">
                    <g id="Group-+-Group-+-Group" transform="translate(239.000000, 278.000000)" strokeWidth="4">
                        <g id="Group" transform="translate(209.257453, 0.000000)" stroke="#090909" fill="#FFFF00">
                            <path d="M123.200097,127.553092 C128.02692,125.313408 135.838448,125.306773 140.67957,127.553092 L259.597102,182.731778 C264.423925,184.971462 264.438224,188.596074 259.597102,190.842393 L140.67957,246.021079 C135.852747,248.260763 128.041219,248.267398 123.200097,246.021079 L4.28256586,190.842393 C-0.544257445,188.602709 -0.558556403,184.978097 4.28256586,182.731778 L123.200097,127.553092 L123.200097,127.553092 Z" id="Rectangle-1"></path>
                            <path d="M277.848153,191.200412 L396.765684,246.379097 C401.592507,248.618782 401.606806,252.243393 396.765684,254.489713 L277.848153,309.668398 C273.02133,311.908083 265.209802,311.914718 260.368679,309.668398 L141.451148,254.489713 C136.624325,252.250028 136.610026,248.625417 141.451148,246.379097 L260.368679,191.200412 C265.195503,188.960727 273.007031,188.954092 277.848153,191.200412 Z" id="Rectangle-1"></path>
                            <path d="M396.166403,254.211641 C400.993226,251.971957 408.804754,251.965322 413.645876,254.211641 L532.563408,309.390327 C537.390231,311.630012 537.40453,315.254623 532.563408,317.500943 L413.645876,372.679628 C408.819053,374.919313 401.007525,374.925948 396.166403,372.679628 L277.248872,317.500943 C272.422049,315.261258 272.40775,311.636647 277.248872,309.390327 L396.166403,254.211641 L396.166403,254.211641 Z" id="Rectangle-1"></path>
                            <path d="M260.196382,64.1838439 C265.023206,61.9441593 272.834734,61.9375244 277.675856,64.1838439 L396.593387,119.362529 C401.42021,121.602214 401.434509,125.226826 396.593387,127.473145 L277.675856,182.651831 C272.849033,184.891515 265.037505,184.89815 260.196382,182.651831 L141.278851,127.473145 C136.452028,125.23346 136.437729,121.608849 141.278851,119.362529 L260.196382,64.1838439 L260.196382,64.1838439 Z" id="Rectangle-1"></path>
                            <path d="M397.537262,127.911111 C402.364085,125.671426 410.175613,125.664791 415.016735,127.911111 L533.934267,183.089796 C538.76109,185.329481 538.775389,188.954092 533.934267,191.200412 L415.016735,246.379097 C410.189912,248.618782 402.378384,248.625417 397.537262,246.379097 L278.619731,191.200412 C273.792908,188.960727 273.778609,185.336116 278.619731,183.089796 L397.537262,127.911111 L397.537262,127.911111 Z" id="Rectangle-1"></path>
                            <path d="M550.814459,190.92234 L669.73199,246.101026 C674.558813,248.340711 674.573112,251.965322 669.73199,254.211641 L550.814459,309.390327 C545.987636,311.630012 538.176108,311.636647 533.334986,309.390327 L414.417454,254.211641 C409.590631,251.971957 409.576332,248.347345 414.417454,246.101026 L533.334986,190.92234 C538.161809,188.682656 545.973337,188.676021 550.814459,190.92234 Z" id="Rectangle-1"></path>
                            <path d="M397.885217,1.68225245 C402.71204,-0.557432174 410.523568,-0.564067005 415.36469,1.68225245 L534.282222,56.860938 C539.109045,59.1006227 539.123344,62.7252341 534.282222,64.9715535 L415.36469,120.150239 C410.537867,122.389924 402.726339,122.396559 397.885217,120.150239 L278.967686,64.9715535 C274.140863,62.7318689 274.126564,59.1072575 278.967686,56.860938 L397.885217,1.68225245 L397.885217,1.68225245 Z" id="Rectangle-1"></path>
                            <path d="M533.016629,63.7760106 C537.843452,61.536326 545.65498,61.5296912 550.496102,63.7760106 L669.413633,118.954696 C674.240456,121.194381 674.254755,124.818992 669.413633,127.065312 L550.496102,182.243997 C545.669279,184.483682 537.857751,184.490317 533.016629,182.243997 L414.099097,127.065312 C409.272274,124.825627 409.257975,121.201016 414.099097,118.954696 L533.016629,63.7760106 L533.016629,63.7760106 Z" id="Rectangle-1"></path>
                            <path d="M668.814352,126.787241 C673.641175,124.547556 681.452703,124.540921 686.293826,126.787241 L805.211357,181.965926 C810.03818,184.205611 810.052479,187.830222 805.211357,190.076542 L686.293826,245.255227 C681.467002,247.494912 673.655474,247.501547 668.814352,245.255227 L549.896821,190.076542 C545.069998,187.836857 545.055699,184.212246 549.896821,181.965926 L668.814352,126.787241 L668.814352,126.787241 Z" id="Rectangle-1"></path>
                        </g>
                        <g id="Group" transform="translate(406.092850, 507.986011) rotate(-302.000000) translate(-406.092850, -507.986011) translate(35.092850, 261.986011)" stroke="#000000" fill="#FF0000">
                            <path d="M112.896397,167.577639 C117.319535,164.63517 124.477754,164.626453 128.913995,167.577639 L237.885994,240.070698 C242.309131,243.013168 242.322234,247.775136 237.885994,250.726322 L128.913995,323.219382 C124.490857,326.161851 117.332638,326.170568 112.896397,323.219382 L3.92439834,250.726322 C-0.498739094,247.783853 -0.511842174,243.021884 3.92439834,240.070698 L112.896397,167.577639 L112.896397,167.577639 Z" id="Rectangle-1"></path>
                            <path d="M238.593041,251.196682 C243.016179,248.254213 250.174399,248.245496 254.610639,251.196682 L363.582638,323.689742 C368.005775,326.632211 368.018878,331.394179 363.582638,334.345366 L254.610639,406.838425 C250.187502,409.780894 243.029282,409.789611 238.593041,406.838425 L129.621043,334.345366 C125.197905,331.402896 125.184802,326.640928 129.621043,323.689742 L238.593041,251.196682 L238.593041,251.196682 Z" id="Rectangle-1"></path>
                            <path d="M379.051075,333.980039 L488.023073,406.473098 C492.446211,409.415568 492.459314,414.177536 488.023073,417.128722 L379.051075,489.621782 C374.627937,492.564251 367.469718,492.572968 363.033477,489.621782 L254.061478,417.128722 C249.638341,414.186253 249.625238,409.424284 254.061478,406.473098 L363.033477,333.980039 C367.456614,331.03757 374.614834,331.028853 379.051075,333.980039 Z" id="Rectangle-1"></path>
                            <path d="M254.452752,84.3239223 L363.424751,156.816981 C367.847888,159.759451 367.860991,164.521419 363.424751,167.472606 L254.452752,239.965665 C250.029614,242.908134 242.871395,242.916851 238.435154,239.965665 L129.463156,167.472606 C125.040018,164.530136 125.026915,159.768168 129.463156,156.816981 L238.435154,84.3239223 C242.858292,81.3814529 250.016511,81.3727362 254.452752,84.3239223 Z" id="Rectangle-1"></path>
                            <path d="M364.289686,168.047999 C368.712823,165.10553 375.871043,165.096813 380.307283,168.047999 L489.279282,240.541058 C493.70242,243.483528 493.715523,248.245496 489.279282,251.196682 L380.307283,323.689742 C375.884146,326.632211 368.725926,326.640928 364.289686,323.689742 L255.317687,251.196682 C250.89455,248.254213 250.881446,243.492244 255.317687,240.541058 L364.289686,168.047999 L364.289686,168.047999 Z" id="Rectangle-1"></path>
                            <path d="M488.730121,250.831356 C493.153259,247.888887 500.311478,247.88017 504.747719,250.831356 L613.719718,323.324415 C618.142855,326.266884 618.155958,331.028853 613.719718,333.980039 L504.747719,406.473098 C500.324581,409.415568 493.166362,409.424284 488.730121,406.473098 L379.758123,333.980039 C375.334985,331.03757 375.321882,326.275601 379.758123,323.324415 L488.730121,250.831356 L488.730121,250.831356 Z" id="Rectangle-1"></path>
                            <path d="M364.60854,2.21012199 C369.031677,-0.73234734 376.189897,-0.741064096 380.626138,2.21012199 L489.598136,74.7031812 C494.021274,77.6456505 494.034377,82.4076191 489.598136,85.3588052 L380.626138,157.851864 C376.203,160.794334 369.04478,160.803051 364.60854,157.851864 L255.636541,85.3588052 C251.213404,82.4163359 251.200301,77.6543673 255.636541,74.7031812 L364.60854,2.21012199 L364.60854,2.21012199 Z" id="Rectangle-1"></path>
                            <path d="M488.43839,83.7881161 C492.861527,80.8456467 500.019747,80.83693 504.455987,83.7881161 L613.427986,156.281175 C617.851124,159.223645 617.864227,163.985613 613.427986,166.936799 L504.455987,239.429858 C500.03285,242.372328 492.87463,242.381045 488.43839,239.429858 L379.466391,166.936799 C375.043254,163.99433 375.03015,159.232361 379.466391,156.281175 L488.43839,83.7881161 L488.43839,83.7881161 Z" id="Rectangle-1"></path>
                            <path d="M612.878825,166.571473 C617.301963,163.629004 624.460182,163.620287 628.896423,166.571473 L737.868422,239.064532 C742.291559,242.007001 742.304662,246.76897 737.868422,249.720156 L628.896423,322.213215 C624.473285,325.155685 617.315066,325.164401 612.878825,322.213215 L503.906827,249.720156 C499.483689,246.777687 499.470586,242.015718 503.906827,239.064532 L612.878825,166.571473 L612.878825,166.571473 Z" id="Rectangle-1"></path>
                        </g>
                        <g id="Group" transform="translate(816.339647, 505.329906) scale(-1, 1) rotate(-303.000000) translate(-816.339647, -505.329906) translate(437.839647, 267.329906)" stroke="#090909" fill="#3CFD03">
                            <path d="M115.154325,162.103225 C119.665925,159.25688 126.967309,159.248448 131.492275,162.103225 L242.643713,232.228086 C247.155314,235.074431 247.168679,239.680836 242.643713,242.535613 L131.492275,312.660475 C126.980674,315.50682 119.67929,315.515252 115.154325,312.660475 L4.00288631,242.535613 C-0.508713875,239.689268 -0.522079017,235.082863 4.00288631,232.228086 L115.154325,162.103225 L115.154325,162.103225 Z" id="Rectangle-1"></path>
                            <path d="M243.364902,242.990608 C247.876502,240.144263 255.177886,240.135831 259.702852,242.990608 L370.854291,313.115469 C375.365891,315.961814 375.379256,320.568219 370.854291,323.422996 L259.702852,393.547857 C255.191252,396.394202 247.889868,396.402634 243.364902,393.547857 L132.213464,323.422996 C127.701863,320.576651 127.688498,315.970246 132.213464,313.115469 L243.364902,242.990608 L243.364902,242.990608 Z" id="Rectangle-1"></path>
                            <path d="M370.294147,323.069604 C374.805747,320.223259 382.107131,320.214827 386.632096,323.069604 L497.783535,393.194465 C502.295135,396.04081 502.3085,400.647215 497.783535,403.501992 L386.632096,473.626853 C382.120496,476.473198 374.819112,476.48163 370.294147,473.626853 L259.142708,403.501992 C254.631108,400.655647 254.617742,396.049242 259.142708,393.194465 L370.294147,323.069604 L370.294147,323.069604 Z" id="Rectangle-1"></path>
                            <path d="M259.541807,81.5692346 L370.693246,151.694096 C375.204846,154.540441 375.218211,159.146846 370.693246,162.001623 L259.541807,232.126484 C255.030207,234.972829 247.728823,234.981261 243.203857,232.126484 L132.052419,162.001623 C127.540818,159.155278 127.527453,154.548873 132.052419,151.694096 L243.203857,81.5692346 C247.715458,78.7228896 255.016842,78.7144576 259.541807,81.5692346 Z" id="Rectangle-1"></path>
                            <path d="M371.575479,162.558219 C376.08708,159.711874 383.388464,159.703442 387.913429,162.558219 L499.064868,232.683081 C503.576468,235.529426 503.589833,240.135831 499.064868,242.990608 L387.913429,313.115469 C383.401829,315.961814 376.100445,315.970246 371.575479,313.115469 L260.424041,242.990608 C255.912441,240.144263 255.899075,235.537858 260.424041,232.683081 L371.575479,162.558219 L371.575479,162.558219 Z" id="Rectangle-1"></path>
                            <path d="M498.504724,242.637216 C503.016324,239.790871 510.317708,239.782439 514.842673,242.637216 L625.994112,312.762077 C630.505712,315.608422 630.519077,320.214827 625.994112,323.069604 L514.842673,393.194465 C510.331073,396.04081 503.029689,396.049242 498.504724,393.194465 L387.353285,323.069604 C382.841685,320.223259 382.82832,315.616854 387.353285,312.762077 L498.504724,242.637216 L498.504724,242.637216 Z" id="Rectangle-1"></path>
                            <path d="M388.23866,2.13792189 L499.390099,72.2627832 C503.901699,75.1091281 503.915064,79.7155331 499.390099,82.5703101 L388.23866,152.695171 C383.72706,155.541516 376.425676,155.549948 371.900711,152.695171 L260.749272,82.5703101 C256.237672,79.7239651 256.224307,75.1175601 260.749272,72.2627832 L371.900711,2.13792189 C376.412311,-0.708423071 383.713695,-0.716855069 388.23866,2.13792189 Z" id="Rectangle-1"></path>
                            <path d="M514.545107,81.0509321 L625.696546,151.175793 C630.208146,154.022138 630.221511,158.628543 625.696546,161.48332 L514.545107,231.608182 C510.033507,234.454527 502.732123,234.462959 498.207158,231.608182 L387.055719,161.48332 C382.544119,158.636975 382.530753,154.03057 387.055719,151.175793 L498.207158,81.0509321 C502.718758,78.2045871 510.020142,78.1961551 514.545107,81.0509321 Z" id="Rectangle-1"></path>
                            <path d="M625.136402,161.129928 C629.648002,158.283583 636.949386,158.275151 641.474351,161.129928 L752.62579,231.25479 C757.13739,234.101135 757.150755,238.70754 752.62579,241.562317 L641.474351,311.687178 C636.962751,314.533523 629.661367,314.541955 625.136402,311.687178 L513.984963,241.562317 C509.473363,238.715972 509.459998,234.109567 513.984963,231.25479 L625.136402,161.129928 L625.136402,161.129928 Z" id="Rectangle-1"></path>
                        </g>
                    </g>
                    <g id="green_robot">
                        <path d="M428.318172,973.806862 C416.652969,973.233222 404.516298,975.967435 392.813378,976.700187 C380.177996,977.489466 367.511183,977.742789 354.850656,977.734415 C330.040885,977.717666 305.231115,976.827895 280.429725,976.197728 C256.09771,975.580123 231.771981,975.134191 207.433679,974.809686 C194.507034,974.635919 181.580389,974.370035 168.653744,974.508211 C157.330095,974.631732 146.05045,973.978536 137.04224,981.469358 C133.27258,984.603444 133.689569,991.296613 137.04224,994.493506 C145.47421,1002.53285 157.548019,1002.23346 168.653744,1003.0688 C180.807179,1003.9816 192.979473,1004.52803 205.151767,1005.14772 C231.002962,1006.46668 256.84787,1007.65374 282.711637,1008.66284 C307.494168,1009.62798 332.28508,1010.86529 357.086469,1011.15211 C368.998931,1011.28819 380.915584,1011.17723 392.813378,1010.59731 C405.658302,1009.97342 420.437298,1010.0467 432.6515,1005.77789 C449.926141,999.742106 447.116183,974.732224 428.318172,973.806862" id="Fill-124" fill="#0A0A08"></path>
                        <path d="M417.455515,783.171932 C409.534828,771.642598 396.918305,767.618741 383.652202,765.177629 C370.725557,762.797231 356.81616,760.326809 343.83713,763.293409 C334.753485,765.368145 330.418061,778.988963 338.034912,785.248761 C348.13274,793.541424 361.153679,797.935844 373.613046,801.559828 C386.443302,805.292677 400.470042,808.054106 412.703103,801.275101 C418.633141,797.990277 421.445195,788.983705 417.455515,783.171932" id="Fill-125" fill="#0A0A08"></path>
                        <path d="M245.587084,760.282844 C237.012626,759.382606 227.977176,761.446873 219.572447,763.04218 C210.197538,764.821721 200.066183,765.443514 193.723347,773.495415 C185.109076,784.43436 187.975611,798.741872 201.683847,803.624095 C212.070844,807.323448 221.41013,802.169059 230.950576,798.392244 C239.908496,794.847817 248.346752,791.22802 255.930076,785.232013 C267.042088,776.44736 256.579656,761.438499 245.587084,760.282844" id="Fill-126" fill="#0A0A08"></path>
                        <path d="M300.21887,688.433341 C287.233553,688.433341 277.965511,698.943103 277.282405,711.349646 C276.025153,734.226172 274.501782,765.167161 291.849763,782.864176 C298.230317,789.37311 309.621019,787.851079 314.155508,779.991787 C325.342954,760.601068 321.238027,732.942809 323.15324,711.349646 C324.249145,699.00591 311.827496,688.433341 300.21887,688.433341" id="Fill-127" fill="#0A0A08"></path>
                        <path d="M469.092947,529.082747 C464.631798,518.49971 452.187099,514.50935 442.139561,520.002899 C423.454702,530.223747 399.579489,539.594602 385.13995,555.650251 C378.813878,562.68258 382.79308,575.68998 393.282752,575.2922 C414.941011,574.469424 436.896821,562.561152 456.78026,554.701861 C467.303458,550.544015 473.880981,540.43622 469.092947,529.082747" id="Fill-128" fill="#0A0A08"></path>
                        <path d="M213.541828,561.937266 C209.602439,548.308073 194.836015,540.850749 183.537511,533.795391 C177.856828,530.246776 172.090233,526.752594 166.149718,523.662473 C156.950824,518.878648 150.698092,516.988147 140.254519,518.432716 C128.105275,520.113859 124.633164,535.853378 129.471489,544.91229 C136.537244,558.143703 153.740641,562.806101 166.88521,568.13635 C178.460309,572.830152 193.046527,580.427747 205.633714,575.853279 C211.326969,573.784824 215.354366,568.205439 213.541828,561.937266" id="Fill-129" fill="#0A0A08"></path>
                        <path d="M421.696645,7.84254312 C406.333027,31.8328532 392.243424,56.4428624 381.950721,82.9831505 C378.924935,90.7921963 390.225534,96.449044 395.585618,90.9136239 C415.443912,70.4007468 431.907626,46.8500881 447.281721,22.7865028 C457.574424,6.67642018 431.953725,-8.17123486 421.696645,7.84254312" id="Fill-131" fill="#0A0A08"></path>
                        <path d="M215.830027,85.5394092 C208.642737,71.5270917 200.483172,59.0200569 190.523641,46.7830936 C185.622454,40.7598697 180.823943,34.4288899 175.256412,28.9834936 C168.842332,22.7090404 164.06687,21.0781431 155.035611,22.3636 C150.731618,22.9770183 147.173595,27.4447138 146.054641,31.3387688 C141.58092,46.9066147 158.034156,59.4324917 167.725473,69.5633156 C178.426782,80.7534899 189.945305,89.9484844 202.700126,98.6556752 C211.161431,104.431857 219.884664,93.4426661 215.830027,85.5394092" id="Fill-133" fill="#0A0A08"></path>
                        <path d="M332.540721,476.640712 C332.132115,461.897736 330.967061,447.225941 329.183859,432.587644 C328.289115,425.258028 324.743664,419.155248 317.273492,416.959084 C303.118931,412.792864 288.459374,411.419477 273.822866,413.383253 C272.012424,413.626108 270.32142,414.078321 268.743569,414.677084 C263.959725,413.781033 257.903962,417.235437 257.434588,423.030461 C254.633011,457.607994 256.288393,492.33836 256.554511,526.987075 C256.615279,534.978262 263.11108,541.089417 270.958427,541.37833 C294.110721,542.228323 325.967389,548.377161 331.361,517.963754 C333.739302,504.560668 332.915802,490.181974 332.540721,476.640712" id="Fill-134" fill="#0A0A08"></path>
                        <path d="M457.846828,481.152372 C458.791863,461.723969 141.499198,471.944817 117.621889,473.609211 C122.127042,473.295174 120.513569,401.255156 118.545969,334.379994 C117.007931,282.268745 115.275019,233.281114 116.088042,223.778363 C117.064508,212.152725 118.851901,200.30098 121.477462,188.486919 C123.874622,177.566817 127.005179,166.722083 130.864943,156.097174 C147.290939,110.921949 177.003992,70.2856 221.78521,50.5724697 C246.69137,39.6000275 274.499687,37.2049743 301.706618,37.0333009 C331.168221,36.8700018 361.667057,39.4178862 387.612546,53.3569284 C428.676489,75.4253339 450.74126,121.919514 460.344569,167.521829 C461.989473,175.383215 463.137763,183.38487 463.890019,191.491204 C467.100202,225.294114 463.881637,260.6044 465.195466,293.783424 C465.859714,310.873301 466.427573,327.795692 466.693691,344.642714 C467.401943,390.242936 465.847141,435.198336 457.846828,481.152372" id="Fill-135" fill="#149D8F"></path>
                        <path d="M466.693691,344.642714 C405.608011,326.660972 342.473011,317.137286 279.18295,315.818332 C225.328565,318.502299 171.694198,324.718132 118.545969,334.379994 C117.007931,282.268745 115.275019,233.281114 116.088042,223.778363 C117.064508,212.152725 118.851901,200.30098 121.477462,188.486919 C123.361244,188.068204 125.226168,187.666237 127.112046,187.268457 C236.771653,164.0758 355.26974,160.135686 463.890019,191.491204 C467.100202,225.294114 463.881637,260.6044 465.195466,293.783424 C465.859714,310.873301 466.427573,327.795692 466.693691,344.642714" id="Fill-136" fill="#0A0A08"></path>
                        <path d="M336.651935,556.485589 C342.468821,559.550587 347.277809,563.936633 350.586477,570.185963 C346.90692,565.0232 342.152412,560.367083 336.651935,556.485589 L336.651935,556.485589 Z M403.969393,565.211622 C395.491324,529.380035 361.979275,504.290596 320.894378,499.609356 C288.970656,495.970717 258.779847,505.320637 235.118366,522.661743 C225.435431,526.42809 216.36855,531.745778 208.351473,538.63365 C178.244481,564.503993 170.066057,605.064972 179.575073,639.994228 C184.474164,657.986437 194.880019,674.954886 211.88016,686.36698 C228.109187,697.26196 248.642206,702.94393 268.557076,706.356462 C311.69758,713.75098 356.013615,706.840079 381.02245,672.56402 C402.825294,642.678194 412.156198,599.828934 403.969393,565.211622 L403.969393,565.211622 Z" id="Fill-137" fill="#149D8F"></path>
                        <path d="M292.698408,584.610716 C292.721458,584.225497 292.738221,583.898899 292.748698,583.612079 C293.494668,581.928842 294.135866,580.212108 294.565427,578.417912 C294.383126,578.905716 296.403111,577.837991 294.215492,577.19945 C301.582989,579.347461 295.845729,585.142484 292.769653,585.686815 C292.721458,585.695189 292.677454,585.691002 292.62926,585.699376 C292.658595,585.341374 292.692122,584.985466 292.698408,584.610716 L292.698408,584.610716 Z M308.401485,522.462853 C281.443908,518.041217 252.68008,534.346002 244.891405,561.156361 C240.82629,575.147743 243.328221,590.52298 250.737626,602.812283 C252.845618,607.485149 255.599,611.959125 259.014534,616.062538 C274.730183,634.923582 301.740145,641.824015 324.066844,631.236791 C344.492996,621.551899 357.668996,598.089171 356.811969,575.736039 C355.785214,548.973831 334.535561,526.750501 308.401485,522.462853 L308.401485,522.462853 Z" id="Fill-138" fill="#0A0A08"></path>
                        <path d="M317.876973,659.303297 C316.680489,657.213906 314.348286,654.862818 312.158573,653.813936 C309.038492,652.319121 305.390366,651.362356 301.93921,652.478233 C299.14392,653.382659 296.855721,654.892128 295.051565,657.226468 C294.561237,657.858728 294.135866,658.539141 293.773359,659.250958 C291.602504,661.512022 289.98484,664.363475 289.28916,667.482906 C287.933424,673.541721 290.294962,678.821725 295.305111,682.257286 C298.760458,684.627217 303.70984,685.083617 307.657611,683.927961 C311.762538,682.724154 315.21579,679.874794 317.449508,676.269653 C319.121653,673.566844 320.160981,670.072662 320.058305,666.875769 C319.970298,664.17924 319.211756,661.635543 317.876973,659.303297" id="Fill-139" fill="#0A0A08"></path>
                        <path d="M362.310351,765.864323 C357.524412,741.400864 334.818443,727.271306 311.169534,726.316635 C310.97466,726.308261 310.777691,726.306167 310.584912,726.299886 C289.46308,720.978011 264.173458,726.224517 249.692011,741.166383 C234.592416,756.744697 230.833233,781.440543 242.525676,800.01058 C251.592557,814.412303 268.829481,822.154354 285.316244,823.789439 C318.995927,827.128695 370.639645,808.418389 362.310351,765.864323" id="Fill-140" fill="#149D8F"></path>
                        <path d="M209.260885,768.089796 C200.887588,748.320139 168.067027,751.121347 158.348469,768.089796 C152.298992,778.653991 151.775137,791.975428 150.811244,803.32262 C149.962599,813.342484 149.147481,823.37491 148.59429,833.405242 C147.446,854.165161 147.04787,874.966952 146.712603,895.741527 C146.029496,938.119732 147.280462,980.299048 151.421011,1022.57467 C154.103149,1049.95239 209.644347,1050.64536 209.994282,1022.57467 C210.516042,980.294861 210.40708,938.01924 210.8555,895.741527 C211.077615,874.954391 211.817298,854.184004 212.393538,833.405242 C212.967683,812.624387 217.738954,788.100215 209.260885,768.089796" id="Fill-141" fill="#149D8F"></path>
                        <path d="M430.918588,758.260448 C423.004187,747.440837 399.034679,747.057712 391.264863,758.260448 C377.420424,778.22062 381.560973,804.907459 380.819195,826.772787 C380.045985,849.620004 379.383832,872.469314 378.505851,895.312343 C376.749889,940.960717 373.971363,986.588156 371.882229,1032.23025 C371.370947,1043.43927 385.202813,1052.97761 400.069817,1052.97761 C416.694878,1052.97761 426.581069,1043.41833 428.255309,1032.23025 C435.067519,986.676086 438.677927,941.111455 440.551233,895.312343 C441.487885,872.458846 441.967737,849.636752 441.663901,826.772787 C441.378924,805.056103 445.297359,777.923332 430.918588,758.260448" id="Fill-142" fill="#149D8F"></path>
                        <path d="M349.452855,925.585481 C349.790218,907.304358 350.159011,889.021141 350.431416,870.740018 C350.932221,847.281477 322.646149,835.034046 294.146344,834.539961 C265.648634,833.980976 236.937191,845.175338 236.583065,868.673657 C236.193317,886.948499 235.897863,905.225435 235.568882,923.500277 C233.16334,983.85185 233.416885,1044.31229 232.507473,1104.68898 C231.514244,1151.3674 344.93513,1153.49238 345.928359,1106.74488 C347.210756,1046.38284 349.662397,985.978925 349.452855,925.585481" id="Fill-143" fill="#3A3A39"></path>
                        <g id="Fill-122-+-Fill-123-+-Fill-144" transform="translate(0.674303, 507.000000)">
                            <path d="M37.3273244,282.827563 C32.8242672,272.642306 25.0963588,265.513673 23.5436527,253.806385 C22.0684771,242.674831 26.2593168,231.507686 32.2249771,222.279194 C36.3948626,215.830974 28.1891985,208.021928 21.8149313,211.876206 C6.6545687,221.046077 0.0539961832,237.819824 0.460507634,255.211176 C0.831396947,271.086778 8.65569466,293.523653 26.4332366,296.984338 C35.1795191,298.686417 40.7700992,290.611486 37.3273244,282.827563" id="Fill-122" fill="#0A0A08"></path>
                            <path d="M84.7781069,249.355439 C81.9073817,235.973288 74.787145,223.600242 62.9564046,216.350182 C55.467374,211.761059 46.4067786,220.897433 50.9328855,228.363132 C55.8445496,236.467372 60.4544733,244.512993 61.1396756,254.193697 C61.8144008,263.725758 56.8063473,271.938864 57.0703702,281.246912 C57.3783969,292.016277 69.3285763,295.78053 77.0145763,289.499796 C87.8877099,280.612558 87.4707214,261.916906 84.7781069,249.355439" id="Fill-123" fill="#0A0A08"></path>
                            <path d="M166.053156,17.9166734 C166.700641,16.2753083 166.782363,14.4727376 166.042679,12.6952899 C161.67792,2.20227706 151.789634,-1.49707523 139.904412,0.732585321 C126.512584,3.2448789 117.592382,11.4537982 108.37463,20.0584037 C107.40445,20.9628294 106.465702,21.9112202 105.508095,22.8344881 C99.362229,27.4717633 93.597729,32.7078018 88.4115649,37.8475358 C71.9625191,54.1544147 57.9755916,72.4041339 47.3015229,92.3161541 C36.8789046,111.7634 28.8450649,132.391424 24.3441031,153.666363 C21.6200573,166.541868 17.2552977,184.060928 18.8624847,199.364983 C17.8503969,206.700881 17.0625191,214.072369 16.5638092,221.479448 C15.4259962,238.431149 43.0457252,250.176121 55.3500305,236.136587 C107.069183,177.131185 118.210531,99.3484826 166.241744,38.5865688 C171.031874,32.5256606 170.128748,24.2664954 166.053156,17.9166734" id="Fill-144" fill="#149D8F"></path>
                        </g>
                        <g id="Fill-120-+-Fill-121-+-Fill-145" transform="translate(594.745355, 549.744356) rotate(-66.000000) translate(-594.745355, -549.744356) translate(526.245355, 399.244356)">
                            <path d="M114.564824,209.738321 C108.333046,204.85191 97.4976298,211.048901 102.614645,218.952158 C109.345134,229.353053 111.260347,244.914618 108.877855,256.891978 C106.396878,269.361328 94.1994389,279.728727 97.0366374,292.6796 C97.753271,295.95605 100.705718,298.604426 103.848847,299.483728 C124.765328,305.345747 134.921828,274.532466 136.260802,259.100703 C138.008382,238.968857 130.091885,221.918758 114.564824,209.738321" id="Fill-120" fill="#0A0A08"></path>
                            <path d="M78.0102252,201.910433 C79.5440725,197.505545 75.5900153,193.862719 71.6024313,193.506811 C55.8742099,192.108301 44.3074924,210.458512 41.0700687,223.754826 C37.5141412,238.353345 38.5555649,259.054644 53.583916,266.826006 C59.3567977,269.809354 66.6279046,266.160248 66.6467634,259.335183 C66.6760992,248.810767 62.2547634,239.356169 64.7986031,228.764758 C67.258626,218.5146 74.6407901,211.582763 78.0102252,201.910433" id="Fill-121" fill="#0A0A08"></path>
                            <path d="M111.627046,106.905958 C105.219252,85.9764587 94.5409924,65.7064367 81.7086412,48.0219835 C69.4671985,31.1519321 49.0075191,3.57532294 26.8358817,0.885075229 C18.5589733,-0.119842202 10.162626,3.90401468 5.63023282,10.3501413 C2.74903053,12.7054165 0.75628626,16.4466404 0.588652672,21.2744312 C-0.0253053435,38.9212 8.3940916,56.9008477 13.8107519,73.4128972 C19.7219313,91.4365101 25.9683779,109.319853 32.292355,127.203196 C44.6804771,162.228756 56.9847824,197.285719 69.5153931,232.263127 C74.0310229,244.86856 89.4742672,248.846358 100.200721,241.724006 C119.405244,228.974116 122.068523,206.202268 122.034996,185.019446 C121.993088,158.818317 119.323523,132.043549 111.627046,106.905958" id="Fill-145" fill="#149D8F"></path>
                        </g>
                        <path d="M430.815912,200.039283 C429.914882,193.25609 419.896679,192.726415 419.814958,200.039283 C419.364443,240.349033 421.64426,281.335009 424.732908,321.527519 C425.510309,331.610191 441.106519,331.842578 440.645527,321.527519 C438.849752,281.152868 436.127802,240.101991 430.815912,200.039283" id="Fill-146" fill="#515251"></path>
                        <g id="eyes" transform="translate(135.820611, 159.111927)">
                            <ellipse id="Oval-11" fill="#4A4A4A" cx="184.23576" cy="77.4623853" rx="81.8825602" ry="77.4623853"></ellipse>
                            <ellipse id="Oval-11" fill="#4A4A4A" cx="72.9266552" cy="85.0816363" rx="72.9266552" ry="69.8431343"></ellipse>
                            <g id="Group" transform="translate(130.500330, 20.318003)">
                                <ellipse id="Oval-13" stroke="#979797" fill="#FFFFFF" cx="58.8530901" cy="58.4142578" rx="58.8530901" ry="58.4142578"></ellipse>
                                <ellipse id="Oval-15" fill="#7ED321" cx="57.0889459" cy="64.5194364" rx="40.5803652" ry="39.451593"></ellipse>
                                <ellipse id="Oval-16" fill="#417505" cx="60.9537425" cy="64.5194364" rx="21.2563818" ry="20.6651202"></ellipse>
                                <ellipse id="Oval-17" fill="#000000" cx="60.9537425" cy="64.5194364" rx="13.5267884" ry="13.150531"></ellipse>
                                <ellipse id="Oval-18" fill="#FFFFFF" cx="88.0073193" cy="38.2183744" rx="17.3915851" ry="16.9078256"></ellipse>
                                <ellipse id="Oval-19" fill="#FFFFFF" cx="59.9261479" cy="59.027301" rx="2.97154452" ry="2.97763833"></ellipse>
                            </g>
                            <g id="Group" transform="translate(20.470640, 40.636005)">
                                <ellipse id="Oval-13" stroke="#979797" fill="#FFFFFF" cx="48.6177701" cy="48.2552564" rx="48.6177701" ry="48.2552564"></ellipse>
                                <ellipse id="Oval-15" fill="#7ED321" cx="46.1746239" cy="50.3999746" rx="34.9442033" ry="33.6498882"></ellipse>
                                <ellipse id="Oval-16" fill="#417505" cx="49.5026433" cy="50.3999746" rx="18.3041065" ry="17.6261319"></ellipse>
                                <ellipse id="Oval-17" fill="#000000" cx="49.5026433" cy="50.3999746" rx="11.6480678" ry="11.2166294"></ellipse>
                                <ellipse id="Oval-18" fill="#FFFFFF" cx="72.7987789" cy="27.9667158" rx="14.9760871" ry="14.4213807"></ellipse>
                                <ellipse id="Oval-19" fill="#FFFFFF" cx="48.6177701" cy="45.7155061" rx="2.55883001" ry="2.53975034"></ellipse>
                            </g>
                        </g>
                        <path d="M373.926783,345.044713 C372.201534,332.388339 355.916087,315.865571 355.180938,328.67749 C353.742001,353.774173 339.081993,368.895429 321.329929,370.400415 C312.703256,371.13493 302.822175,368.737843 292.361741,363.837286 C280.28475,358.179606 267.03363,343.925734 256.316518,343.761259 C246.788458,343.617974 247.493071,358.357236 254.990922,373.36566 C267.043,397.483163 288.952383,410.129525 303.732601,417.296074 C319.833672,425.102253 333.847592,425.550341 345.857218,422.069192 C370.11795,415.037614 379.787709,388.042782 373.926783,345.044713" id="Fill-209" fill="#000000"></path>
                    </g>
                </g>
            </g>
        </svg>
    </div>
}
export default GreenRobotSvg;